/* src/styles/About.css */
.about-container {
  padding: 20px;
  background-color: #e8f5e9; /* Greenish background */
  color: #2e7d32;
}

h1 {
  color: #1b5e20;
  font-size: 2rem; /* Adjust font size for titles */
}

p {
  color: #2e7d32;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding-top: 20px;
}

.team-member {
  text-align: center;
  flex-basis: calc(33.33% - 20px); /* Three items per row with gap */
  margin-bottom: 20px;
}

.team-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.team-name {
  font-weight: bold;
  margin-top: 10px;
}

.team-role {
  font-style: italic;
  color: #1b5e20;
}
