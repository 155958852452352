/* src/styles/Navbar.css */
.navbar {
  background-color: #2e7d32 !important; /* Darker greenish color */
}

.navbar-brand img {
  margin-right: 10px;
}

.navbar-nav .nav-link {
  color: white !important;
}

.navbar-nav .nav-link:hover {
  color: #a5d6a7 !important;
}
