/* src/pages/Contact.css */
.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .contact-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .alert {
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    text-align: center;
  }
  
  .alert.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .alert.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .contact-container {
      padding: 10px;
    }
    
    .contact-form {
      padding: 15px;
    }
  }