.user-page-container {
  text-align: center;
  padding: 20px;
}

.video-container {
  max-width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Updated title styles */
.user-page-container h1,
.working-plan-section h2 {
  font-size: 2.2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.user-page-container h1::after,
.working-plan-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 3px;
  background: linear-gradient(to right, #3498db, #2ecc71);
  border-radius: 2px;
}

/* Optional: Add hover effect */
.user-page-container h1:hover::after,
.working-plan-section h2:hover::after {
  width: 80%;
  transition: width 0.3s ease;
}

.controls {
  margin-top: 10px;
}

.controls input[type="range"] {
  width: 200px;
}

.controls span {
  margin-left: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.working-plan-section {
  margin-top: 2rem;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.plan-editor, .plan-display pre {
  width: 100%;
  min-height: 500px;
  padding: 20px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre;
  overflow-x: auto;
  tab-size: 2;
  color: #2c3e50;
  text-align: left;
}

.plan-editor {
  resize: vertical;
}

.plan-display {
  position: relative;
}

.plan-display pre {
  margin: 0;
}

/* Optional: Add syntax highlighting */
.json-string {
  color: #22863a;
}

.json-number {
  color: #005cc5;
}

.json-boolean {
  color: #0033cc;
}

.json-null {
  color: #808080;
}

.json-key {
  color: #24292e;
  font-weight: bold;
}

.edit-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.edit-button,
.save-button,
.cancel-button {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.edit-button {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: white;
  width: 150px;
}

.edit-button:hover {
  background: linear-gradient(135deg, #2980b9, #3498db);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.save-button {
  background: linear-gradient(135deg, #2ecc71, #27ae60);
  color: white;
  width: 120px;
}

.save-button:hover {
  background: linear-gradient(135deg, #27ae60, #2ecc71);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.cancel-button {
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: white;
  width: 120px;
}

.cancel-button:hover {
  background: linear-gradient(135deg, #c0392b, #e74c3c);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.edit-button:active,
.save-button:active,
.cancel-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Optional: Add disabled state */
.edit-button:disabled,
.save-button:disabled,
.cancel-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.edit-instructions {
  background: #f8f9fa;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.edit-instructions h4 {
  margin-top: 0;
  color: #2c3e50;
}

.edit-instructions ul {
  margin: 0;
  padding-left: 20px;
}

.edit-instructions li {
  margin: 5px 0;
  color: #666;
}

.plan-editor {
  font-family: 'Consolas', 'Monaco', monospace;
  line-height: 1.5;
  tab-size: 2;
}
