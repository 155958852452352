/* src/styles/Home.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #e8f5e9; /* Greenish background */
  text-align: center;
  padding: 20px;
}

.home-logo {
  width: 300px; /* Increase the size of the logo */
  height: auto;
  margin-bottom: 20px;
}

h1 {
  color: #2e7d32;
}

p {
  color: #2e7d32;
  font-size: 1.2rem;
}

.button-container {
  display: flex;
  gap: 10px; /* Space between the buttons */
}

.login-button, .signup-button {
  padding: 10px 20px;
  background-color: #2e7d32; /* Green button */
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  height: 50px; /* Set a common height for both buttons */
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
}

.login-button:hover, .signup-button:hover {
  background-color: #1b5e20;
}
